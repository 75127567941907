<template>
  <div class="commodity">
    <div class="ul_Li_li">
      <div class="ul_Li_imgf">
        <img
          class="ul_Li_img"
          :src="item.coursePic + '?x-oss-process=image/resize,h_186'"
          alt=""
        />
      </div>
      <div class="ul_li_txt">
        <div class="ul_li_bt">
          <span
            v-if="item.courseType == 1"
            :class="item.topicType ? 'topicTypebx' : ''"
            class="topicType"
            >{{ item.topicType == 1 ? '必修' : '选修' }}
          </span>
          {{ item.courseName }}
        </div>
        <div class="ul_li_baom">
          报名期限: {{ timeformat(item.startTime) }} 至
          {{ timeformat(item.endTime) }}
        </div>
        <div class="ul_li_zy">
          {{ item.courseType == 1 ? '专题' : '专业' }}：{{ item.major }}
        </div>
        <div class="ul_li_foot">
          <div>可得:{{ item.hours }}学时</div>
          <div class="ul_li_foot_txt" v-if="item.price == 0">免费</div>
          <div class="ul_li_foot_txt2" v-if="userinfoShow && item.price != 0">
            ￥{{ item.price }}
          </div>
        </div>
        <!--        $store.state.urlAh=='zyx'-->
        <div class="ul_li_bx" v-if="item.courseType == 1">
          <img
            class="ul_li_btt_bx"
            src="../../assets/img/bixui.png"
            v-if="item.topicType == 1"
            alt=""
          />
          <img
            class="ul_li_btt_bx"
            src="../../assets/img/bixui.png"
            v-else
            alt=""
          />
        </div>
        <div
          class="important"
          :style="{ background: courseYearbackground(item.courseYear) }"
        >
          {{ item.courseYear }}
        </div>

        <div class="addcartype" v-if="item.cartNum > 0">
          <img src="../../assets/img/duihao.png" alt="" />
        </div>
        <div class="hours_type">
          <img src="../../assets/img/xsbg.png" v-if="item.hours == 60" alt="" />
          <img
            v-if="item.hours !== 60"
            src="../../assets/img/biaozhun.png"
            alt=""
          />
          <div class="hours_xs" v-if="item.hours == 60">
            {{ item.hours }}学时
          </div>
        </div>
      </div>
      <div
        class="ul_Li_h"
        v-if="item.orderItemId && item.orderItemId.length > 0"
      >
        <div
          class="ul_Li_h_qxx"
          @click="
            goDetails({
              path: `/VideoDetails`,
              query: {
                courseWrapperId: item.courseWrapperId,
                orderItemId: item.orderItemId,
              },
            })
          "
        >
          点击去学习
        </div>
      </div>
      <div class="ul_Li_h" v-else>
        <div class="ul_Li_h_t1" v-if="item.cartNum == 0" @click="signup(item)">
          立即报名
        </div>
        <div class="ul_Li_h_t2" v-if="item.cartNum == 0" @click="addcar(item)">
          加入购物车
        </div>
        <div
          class="ul_Li_h_t2"
          :style="{ marginTop: item.cartNum > 0 ? '65px' : '' }"
          @click="
            goDetails({
              path: `/VideoDetails`,
              query: {
                courseWrapperId: item.courseWrapperId,
                orderItemId: item.orderItemId,
              },
            })
          "
        >
          试看课程
        </div>
        <div
          class="ul_Li_h_t2"
          v-if="item.cartNum !== 0"
          @click="settlement(item)"
        >
          已加入购物车
        </div>
      </div>
    </div>
    <pay ref="pay" v-if="$store.state.layou.token"></pay>
    <!--    登录弹窗-->
    <loginPopup ref="loginPopupref" />
  </div>
</template>

<script>
import {reactive, ref, toRefs} from 'vue'
import publicFn from '@/util/publicFn'
import { message } from 'ant-design-vue'
import { pay } from '@/components/payorder'
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import loginPopup from '@/components/Centerlabel/loginPopup.vue'
export default {
  name: 'commodity_index',
  components: {
    pay,loginPopup
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  setup(props) {
    const loginPopupref=ref();
    const store = useStore()
    let _this = reactive({
      userinfoShow: true,
      // 用来判断是不是众益学 若是众益学未登录不显示价格
      fnPanduan() {
        let userinfo = localStorage.getItem('userinfo')
        if (
          (!userinfo && store.state.urlAh == 'zyx') ||
          store.state.urlAhVersion == 'ahkx1'
        ) {
          _this.userinfoShow = false
        } else {
          _this.userinfoShow = true
        }
      },
      //购物车结算
      settlement() {
        _this.pay.opencart()
      },
      courseYearbackground(year) {
        let yaarcolor = {
          2022: '#FF0000',
          2021: '#F78001',
          2020: '#5E33F7',
          2019: '#00A5D5',
          2018: '#0BAB22',
        }
        return yaarcolor[year] ? yaarcolor[year] : '#FF0000'
      },
      //组件传值
      item: props.item,
      timeformat: publicFn.timeformat,
      pay: null,
      //试看视频
      goDetails: publicFn.routeJump,

      //立即报名
      signup(item) {
        if (store.state.urlAh == 'zyx'||store.state.urlAh == 'hz') {
          let startTime = item.startTime.replace(/-/g, '')
          let entTile = item.endTime.replace(/-/g, '')
          let time = publicFn.getDateTime('noline')
          if (time > entTile || time < startTime) {
            return message.error('不在报名时间范围内')
          }
        }
        if (localStorage.getItem('userinfo')) {
          _this.pay.paysignup(item)
        } else {
          message.success('请先登录系统');
          _this.fnOpenLogin();
        }
      },
      // 打开登录弹窗
      fnOpenLogin(){
        let stores=store.state.urlAh;
        if(stores == 'yt'||stores == 'ta'||stores == 'lc'){
          setTimeout(()=>{
            loginPopupref.value.getParentdata()
          },500)
        }
      },
      //添加购物车
      async addcar(item) {
        if (store.state.urlAh == 'zyx'||store.state.urlAh == 'hz') {
          let startTime = item.startTime.replace(/-/g, '')
          let entTile = item.endTime.replace(/-/g, '')
          let time = publicFn.getDateTime('noline')
          if (time > entTile || time < startTime) {
            return message.error('不在可学习时间范围内')
          }
        }
        if (localStorage.getItem('userinfo')) {
          let res = await publicFn.addMyCart(item.courseWrapperId)
          if (res.code && res.code == 200) {
            message.success(res.message)
            publicFn.getMyCartTotal()
          }
        } else {
          message.success('请先登录系统');
          _this.fnOpenLogin();
        }
      },
    })
    onMounted(() => {
      _this.fnPanduan()
    })
    return {
      loginPopupref,
      ...toRefs(_this),
    }
  },
}
</script>
<style scoped>
.important {
  position: absolute;
  top: 10px;
  right: -35px;
  z-index: 9;
  width: 120px;
  padding: 0px 10px;
  text-align: center;
  line-height: 20px;
  border: 1px solid #fff;
  line-height: 20px;
  background: #f00;
  transform: rotate(43deg);
  font-size: 17px;
  color: #fff;
}
.hours_type {
  position: absolute;
  top: -5px;
  left: -7px;
  z-index: 9;
  width: 83px;
  height: 32px;
  color: #fff;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  color: #fff;
}
.addcartype {
  position: absolute;
  bottom: 0px;
  right: 0;
  z-index: 0;
  width: 40px;
  background: #fff;
  border-radius: 50%;
  height: 40px;
}
.addcartype img {
  width: 100%;
  height: 100%;
}
.hours_type img {
  width: 100%;
  height: 100%;
}
.hours_xs {
  position: absolute;
  left: 14px;
  top: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-items: center;
}
.topicType {
  display: inline-block;
  margin-right: 5px;
  color: #fff;
  font-size: 15px;
  font-weight: normal;
  padding: 0 5px;
  position: relative;
  top: -1px;
  border-radius: 2px;
  background: linear-gradient(0deg, #0059e8, #485dfa);
}
.topicTypebx {
  background: linear-gradient(0deg, #e96402, #f49712);
}
.ul_Li_img {
  width: 100%;
  height: 199px;
  transition: all 1s;
}
.ul_Li_imgf {
  width: 100%;
  height: 199px;
  overflow: hidden;
}
.commodity {
  /*height: 298px;*/
  width: 334px;
  background: #ffffff;
  min-height: 280px;
}
.ul_li_bt {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #222222;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 5px;
  margin-bottom: 4px;
}
.ul_li_txt {
  padding: 0 12px;
}
.ul_li_zy {
  background: #fff5e3;
  border: 1px solid #ffc350;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  display: inline-block;
  margin: 7px 0;
  padding: 0px 5px;
  border-radius: 5px;
  color: #c88306;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ul_li_baom {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}
.ul_li_foot {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  /*line-height: 22px;*/
  display: flex;
  justify-content: space-between;
}
.ul_li_foot_txt {
  width: 50px;
  background: #f7dca8;
  border-radius: 4px;
  text-align: center;
}
.ul_li_bimg {
  width: 87px;
  height: 75px;
}
.ul_li_btt {
  position: absolute;
  top: -5px;
  right: -5px;
}
.ul_li_b_txt {
  position: absolute;
  transform: rotate(45deg);
  left: 35px;
  top: 15px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
}
.ul_Li_li {
  border: 1px solid #e5e5e5;
  padding-bottom: 15px;
  overflow: hidden;
  border-radius: 5px;
  border-top: none;
  position: relative;
}
.ul_Li_li:hover .ul_Li_img {
  transform: scale(1.2);
}
.ul_li_foot_txt2 {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 700;
  position: relative;
  top: -2px;
  color: red;
}
.ul_Li_h {
  display: none;
}
.ul_Li_li:hover .ul_Li_h {
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  text-align: center;
}
.ul_Li_h_t1 {
  width: 171px;
  margin: 0 auto;
  height: 47px;
  line-height: 47px;
  background: #fe8820;
  border-radius: 6px;
  margin-top: 50px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
.ul_Li_h_qxx {
  width: 171px;
  margin: 0 auto;
  height: 47px;
  line-height: 47px;
  background: #1168f3;
  border-radius: 6px;
  margin-top: 80px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
.ul_Li_h_t2 {
  margin: 0 auto;
  margin-top: 24px;
  width: 171px;
  height: 47px;
  line-height: 47px;
  background: #1168f3;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  border-radius: 6px;
}
.ul_li_btt_bx {
  width: 67px;
  height: 33px;
}
.ul_li_bx {
  position: absolute;
  left: -6px;
  top: -4px;
}
</style>
