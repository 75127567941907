<template>
  <div>
    <Verification Title="微信支付" :maskClosable="false" width="600px" @cancel="handleOkww" v-model:show="visibleqrcode">
      <template #neirong>
        <div class="box" v-if="!completetype">
          <div class="ewm_img" id="qrcode" ref="qrcodehtml">
          </div>
          <div class="box_txt">请在 <span style="font-weight: bold;color: #FE8820">
          {{ countdownTxt }}
          </span> 后完成支付
          </div>
        </div>
        <div class="box_wc" v-else>
          <div  >
            <img :src="require(`@/assets/img/success.png`)" style="width: 120px;" alt="">
          </div>
          <div class="box_txtx">
            支付已成功
            <span style="font-weight: bold;color: #FE8820">
              {{timetxt}}
            </span> 秒后关闭弹框
          </div>
        </div>
      </template>
    </Verification>
  </div>
</template>
<script>
import Verification from '@/components/Verification'

import publicFn from "@/util/publicFn";

import {defineComponent, reactive, toRefs} from 'vue';
import QRCode from 'qrcodejs2'

export default defineComponent({
  name: 'payment_index',
  components: {
    Verification
  },
  props: {
    typepay:{
      type: String,
      default: () => {
        return '1'
      },
    }
  },
  setup(props,{emit}) {
    let data = reactive({
      visibleqrcode: false,
      completetype: false,
      countdownTxt: '15分00秒',//倒计时文字提示
      diffTime: 0,
      timetxtTime: null,
      timetxt: 5,
      props: props,
      countdownTime: null,
      handleOkww() {
        clearInterval(data.paystusTime)
        if(data.completetype){
          emit('paystustrue',1)
        }
        data.visibleqrcode = false;
      },
      qrcodehtml: null,
      paystusTime: null,
      //生成二维码
      qrcodefn(url) {
        setTimeout(() => {
          data.qrcodehtml.innerHTML='';
          new QRCode(data.qrcodehtml, {
            width: 200,
            height: 200,
            text: url
          })
        }, 500)
      },
      //打开弹框
      showModalqrcode(item) {
        data.countdownFun(item.timeExpire);
        data.qrcodefn(item.code_url);
        data.selectpaystus(item.orderNo);
        data.visibleqrcode = true;
      },
      //查询支付状态、
      selectpaystus(orderNo) {

        data.completetype=false;
        data.paystusTime = setInterval(async () => {
          let res = await publicFn.getPaymentStatus(orderNo)
          if(res.rows && res.rows.length>0){
            if(res.rows[0].paymentStatus==2){
              publicFn.getMyCartTotal();
              clearInterval(data.paystusTime)
              data.completetype = true;

              clearInterval(data.timetxtTime)
              data.timetxtTime = setInterval(() => {
                if(data.timetxt==0){

                  if(props.typepay=='1'){
                    publicFn.routeJump({
                      path:`/Personal/Classification`
                    })
                  }

                  clearInterval(data.timetxtTime)
                  data.visibleqrcode = false;
                  emit('paystustrue',1)
                }
               data.timetxt--;

              }, 1000);
            }
          }
        }, 3000);
      },
      //考试时间倒计时
      countdownFun(Time) {
        if (Time > 0) {
          data.countdownTime = setInterval(() => {
            let diffM =
                Math.floor(Time / 60) > 9
                    ? Math.floor(Time / 60)
                    : `0${Math.floor(Time / 60)}`;
            let diffS =
                Math.floor(Time % 60) > 9
                    ? Math.floor(Time % 60)
                    : `0${Math.floor(Time % 60)}`;
            data.countdownTxt = `${diffM}分${diffS}秒`;
            data.diffTime = Time;
            Time--;
            if (Time < 0) {
              clearInterval(data.countdownTime);
              data.visibleqrcode = false;

            }
          }, 1000);
        }
      },
    })
    return {
      ...toRefs(data)
    };
  },
});
</script>

<style scoped>
.box_wc{
  text-align: center;
}
.box {
  text-align: center;
}

.ewm_img {
  /*border: 1px solid #000;*/
  margin: 0 auto;
  text-align: center !important;
  display: flex;
  justify-content: center;
}

.ewm_img img {
  display: inline-block;
}

.box_txtx {
  margin-top: 5px;
  margin-bottom: 30px;
  font-size: 20px;

}
</style>