import axios from 'axios'
let baseURL='/apt';
import { message } from 'ant-design-vue';
const service = axios.create({
  baseURL: baseURL,
  withCredentials:true,
  timeout: 60000
})
// 添加请求拦截器
service.interceptors.request.use(function (config) {

  config.url='/exam/api-v3/'+config.url;

  if(config.data && config.data.formData){
    let data=config.data;
    let formData = new FormData();
    formData.append('file', data.param.file)
    formData.append('dataMsg', JSON.stringify(data))
    config.data=formData;
    config.url='/exam/dataMsg';
    config.headers["Content-Type"] = "multipart/form-data";
  }
  // config.headers["Content-Security-Policy"] = "script-src 'self'; object-src 'none';style-src cdn.example.org third-party.org; child-src https:";
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (res) {
  // 2xx 范围内的状态码都会触发该函数。
  let code = res.data.code;
  // 异常处理
  function funerrShow(msg){
    message.error(msg);
    return Promise.resolve(false)
  }
  if(code == "200"){
    if(res.data.data || res.data.data==0){

      return res.data.data
    }else {
      return res.data
    }
  }else{
    if(code == '106'){
      return res.data
    }
    if(code == "400"){
      if(res.data.data || res.data.data==0){
        return res.data.data
      }else {
        return res.data
      }
    }
    if(code == 10103 || code == 10108 || code === 12003|| code == 16000 ){
      sessionStorage.clear()
      localStorage.clear()
      funerrShow(res.data.message,res)
    }else{
      if(code=='nologin'){
        sessionStorage.clear()
        localStorage.clear()
      }
      funerrShow(res.data.message,res)
    }
  }
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error);
});


export default service