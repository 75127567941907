<template>
  <div class="boxinfof">
    <div class="boxinfos widthcontent">
      <div class="boxinfo_l">
        <div class="boxinfo_l_t" >
          <div class="boxinfo_l_a">{{ itemcourseWrapper.courseYear }}年度</div>
          <div class="boxinfo_l_b">价格:￥{{ itemcourseWrapper.price }}</div>
          <div class="boxinfo_l_c">
            {{
              itemcourseWrapper.courseName.length > 23
                ? itemcourseWrapper.courseName.substring(0, 23) + '...'
                : itemcourseWrapper.courseName
            }}
          </div>
        </div>
        <div class="boxinfo_l_bt">
          可得学时：{{ itemcourseWrapper.hours }}学时 |
          {{ itemcourseWrapper.courseType == 2 ? '所属专业' : '所属专题' }}：{{
            itemcourseWrapper.major
          }}
          | 主讲老师：{{ itemcourseWrapper.expertName }}
          <span v-if="orderItemId.length > 0"
            >| 学习进度：{{ allProgress ? allProgress : '0' }}%</span
          >
        </div>
      </div>
      <div class="boxinfo_r" v-if="!orderItemId.length > 0">
        <div class="boxinfo_r_l" @click="signup">立即报名</div>
        <div class="boxinfo_r_r" @click="addcartinfo">添加购物车</div>
      </div>
      <div v-else class="boxinfo_r">
        <div
          v-show="courseTypeShow"
          class="boxinfo_r_l"
          style="background: #116dff"
          @click="locationto({ path: '/Personal/exercises' })"
        >
          去练习
        </div>
        <div
          class="boxinfo_r_r"
          v-show="courseTypeShow"
          @click="locationto({ path: '/Personal/Examination' })"
        >
          去考试
        </div>
      </div>
    </div>
    <pay ref="payif" @Payment="Paymentfn"></pay>
  </div>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity'
import { pay } from '@/components/payorder'
import publicFn from '@/util/publicFn'
import { message } from 'ant-design-vue'
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
  name: 'Information_index',
  props: {
    allProgress:{
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
    zhangVos: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  components: {
    pay,
  },
  setup(props) {
    const Router = useRouter()
    const store = useStore()
    let data = reactive({
      courseTypeShow: true,
      itemcourseWrapper: props.item.courseWrapper,
      allProgress: props.allProgress,
      zjitem: props.item,
      locationto: Router.push,
      orderItemId: props.item.orderItemId ? props.item.orderItemId : '',
      payif: null,
      //支付成功后刷新页面
      Paymentfn() {
        location.reload()
      },
      // 判断在不在报名时间范围内
      timePanduan() {
        let objs = data.itemcourseWrapper
        let start = objs.startTime.substring(objs.startTime.length - 8, '')
        let ent = objs.endTime.substring(objs.endTime.length - 8, '')
        let startTime = start.replace(/-/g, '')
        let entTile = ent.replace(/-/g, '')
        let time = publicFn.getDateTime('noline')
        if (time > entTile || time < startTime) {
          return false
        } else {
          return true
        }
      },
      //立即报名
      signup() {
        if (store.state.urlAh == 'zyx'||store.state.urlAh == 'hz') {
          let pd = data.timePanduan()
          if (pd) {
            data.payif.paysignup(data.itemcourseWrapper)
          } else {
            return message.error('不在缴费报名时间范围内')
          }
        } else {
          data.payif.paysignup(data.itemcourseWrapper)
        }
      },
      // 添加购物车
      async addcartinfo() {
        if (store.state.urlAh == 'zyx'||store.state.urlAh == 'hz') {
          let pd = data.timePanduan()
          if (pd) {
            let res = await publicFn.addMyCart(
                data.itemcourseWrapper.courseWrapperId
            )
            if (res.code && res.code == 200) {
              message.success(res.message)
              publicFn.getMyCartTotal()
            }
          } else {
            return message.error('不在缴费报名时间范围内')
          }
        } else {
          let res = await publicFn.addMyCart(
              data.itemcourseWrapper.courseWrapperId
          )
          if (res.code && res.code == 200) {
            message.success(res.message)
            publicFn.getMyCartTotal()
          }
        }

      },
    })
    watch(
      () => props.allProgress,
      (e) => {
        data.allProgress = e
      }
    )
    // 安徽众益学如果是专业课就隐藏去考试和去练习
    if (data.zjitem) {
      if (
        store.state.urlAh == 'zyx' &&
        data.zjitem.courseWrapper.courseType == 2
      ) {
        data.courseTypeShow = false
      } else {
        data.courseTypeShow = true
      }
    }
    return {
      ...toRefs(data),
    }
  },
}
</script>

<style scoped>
.boxinfof {
}
.boxinfos {
  background: #fff;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*padding: 33px 0 15px;*/
  padding: 2px 10px;
}

.boxinfo_l_t {
  display: flex;
  align-items: center;
}

.boxinfo_l_a {
  padding: 0px 10px;
  background: #fff4f3;
  border: 1px solid #f04335;
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #f04335;
}

.boxinfo_l_b {
  padding: 0px 10px;
  margin: 0 10px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #d77404;
  background: #fdf6ee;
  border: 1px solid #d77404;
  border-radius: 4px;
}

.boxinfo_l_c {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #333333;
}

.boxinfo_l_bt {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  max-width: 1100px;
  /*border: 1px solid red;*/
  margin: 10px 0;
}

.boxinfo_r {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

.boxinfo_r_l {
  background: #ec8816;
  border-radius: 23px 0px 0px 23px;
  padding: 10px 38px;
  margin-right: 5px;
  cursor: pointer;
}

.boxinfo_r_r {
  background: #116dff;
  border-radius: 0px 23px 23px 0px;
  padding: 10px 38px;
  cursor: pointer;
}
</style>
