<template>
  <div class="ZjAdvertisement">
    <div class="xbox" v-if="control">
      <img
          v-if="$store.state.urlAh != 'yt'"
          class="xbox_gb_img"
          src="../../assets/img/zuoshousuo.png"
          alt=""
          @click="ClickCntrol"
      />
      <div class="x_jf">
        <img class="x_jf_img" src="../../assets/img/liwu.png" alt=""/>
        <div v-if="Dislist&&Dislist.collectivePayment">
          {{ Dislist.collectivePayment.itemName }}</div>
      </div>
      <div class="x_c" @click="toClick(Dislist.collectivePayment.itemValue)">
        <img class="x_c_img" src="../../assets/img/zhcheng.png" alt=""/>
        <div class="x_c_txt">立即进入</div>
      </div>
      <template v-if="$store.state.urlAh != 'ta'&&$store.state.urlAh != 'hz'&&$store.state.urlAh != 'yt'">
        <div class="x_xian"></div>
        <div class="x_sx">扫码手机学习</div>
        <img class="ke_7_img" v-if="Dislist&&Dislist.wxAppCode" :src="Dislist.wxAppCode.itemPath" alt=""/>
        <div class="x_wx" v-if="Dislist&&Dislist.wxAppCode">{{ Dislist.wxAppCode.itemName }}</div>
      </template>
    </div>
    <div class="xbox_bottn" v-else>
      <div class="xbox_b_txt">
        推 荐 使 用 移 动 端 学 习
        <img
            class="xbox_b_img"
            src="../../assets/img/youlan.png"
            alt=""
            @click="clickcontrol"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, toRefs} from "@vue/reactivity";
import {useStore} from 'vuex';
import {watch} from '@vue/runtime-core';
import publicFn from '@/util/publicFn'
export default {
  name: "ZjAdvertisement",
  setup() {
    const store = useStore()
    let data = reactive({
 
      
      Dislist: store.state.layou.Displays || [],//展示数据
      control: null, //广告控制开关
      // 打开
      clickcontrol() {
        // 存储
        data.Setlocal('control',true, 7*24*60*60*1000)
        data.control = data.Getlocal('control')
        if (data.Getlocal('control')) {
          data.control = true
        } else {
          data.control = false
        }
      },

      // 关闭
      ClickCntrol() {
        data.Setlocal('control', false, 7*24*60*60*1000)
        // console.log(data.Getlocal('control'));
        data.control = data.Getlocal('control')
        if (data.Getlocal('control')) {
          data.control = true
        } else {
          data.control = false
        }
      },

      // logc存储
      Setlocal(key,value,sTime){
        let obj = {
          data: value,
          time: Date.now(),
          storageTime: sTime
        }
        localStorage.setItem(key, JSON.stringify(obj))
      },
      // 取值
      Getlocal(key){
        let obj = localStorage.getItem(key)
        obj = JSON.parse(obj)
        if(Date.now()-obj.time>obj.storageTime){
          localStorage.removeItem(key);
          return null
        }
        return obj.data
      },
      // 跳转
      toClick() {
        publicFn.routeJump({
          path:`/collective`
        })
      }
    });
    watch(() => store.state.layou.Displays, (newl) => {
      data.Dislist = newl
    })
    if (!localStorage.getItem('control')) {
      data.control = true
    } else if(data.Getlocal('control')) {
        data.control = true
    } else {
      data.control = false
    }
    
// console.log(localStorage.getItem('control'));
// console.log(data.control);




// Set('name','讨口子',6000)  // 仅存储6s
// Get('name')  // 6s后取值为null

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style scoped>
.ZjAdvertisement {
  z-index: 500;
  position: relative;
  position: fixed;
  left: 5px;
  top: 34%;
  width: 100px;
  height: 200px;
}

.xbox {
   z-index: 500;
  position: fixed;
  left: 5px;
  transform: translateY(-20%);
  padding: 27px 10px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0px 6px 6px 0px;
  width: 170px;
  text-align: center;
}

.xbox_bottn {
  top: -15px;
  position: fixed;
  position: relative;
  left: 0px;
  background: #8df;
  width: 56px;
  background: #ffffff;
  box-shadow: 0px 3px 16px 0px #b8bcbf;
  border-radius: 28px;
  padding: 21px 10px 50px;
  z-index: 500;
  box-sizing: border-box;
}

.xbox_b_img {
  position: absolute;
  /* top: 48%; */
  /* right: -15px; */
  /* transform: translateY(-50%); */
  bottom: 13px;
  right: 0;
  transform: translateX(-50%);

  width: 29px;
  height: 29px;
  margin-top: 10px;
  cursor: pointer;
}

.xbox_b_txt {

  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
}

.xbox_gb_img {
  position: absolute;
  right: 0;
  width: 29px;
  height: 29px;
  right: 0;
  /* top: 50%; */
  bottom: 17%;
  transform: translate(50%, -50%);
  cursor: pointer;
}

.x_jf {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #f04135;
  margin-bottom: 18px;
}

.x_jf_img {
  width: 24px;
  height: 24px;
}

.x_c {
  position: relative;
  cursor: pointer;
}

.x_c_img {
  width: 110px;
  height: 34px;
}

.x_c_txt {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
}

.x_xian {
  margin-top: 22px;
  border-top: 1px solid #e5e5e5;
  margin-bottom: 21px;
}

.x_sx {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #222222;
}

.x_wx {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
</style>

<style scoped>
*,
*::before,
*::after {
  box-sizing: content-box;
}

.box_a {
  position: fixed;
  width: 180px;
  left: 66px;
  top: 500px;
  margin-right: 50px;
  z-index: 999;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #222222;
}

.kuang {
  width: 170px;
  height: 387px;
  background: #fff;
  padding: 23px 13px;
  text-align: center;
}

.ke_b {
  margin-bottom: 10px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #222222;
}

.ke_b_img {
  position: absolute;
  left: 40px;
  width: 21px;
  height: 18px;
}

.ke_2 {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #222222;
}

.ke_3 {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  border-bottom: 2px solid #ccc;
  padding-bottom: 20px;
}

.ke_4 {
  margin-top: 15px;
  /* margin-left: 15px; */
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #222222;
}

.ke_4_img {
  position: absolute;
  left: 40px;
  width: 21px;
  height: 18px;
}

.ke_5 {
  margin-top: 10px;
  padding-bottom: 15px;
  border-bottom: 2px solid #ccc;
}

.ke_6 {
  margin-top: 15px;
  padding-left: 10px;
  padding-bottom: 15px;
  position: relative;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #222222;
}

.ke_6_img {
  position: absolute;
  width: 21px;
  height: 18px;
  left: 20px;
}

.ke_7_img {
  width: 120px;
  height: 120px;
  margin-top: 15px;
}
</style>
