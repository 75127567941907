<template>
  <div class="pay">
    <!-- 确认报名 -->
    <Verification Title="确认报名" width="700px" v-model:show="cartshow">
      <template #neirong>
        <div class="boxn">课程信息</div>
        <div>
          <a-checkbox-group
            v-model:value="modelRefx.signup"
            style="width: 100%"
          >
            <div class="bn_l" v-for="it in cartlist" :key="it.courseWrapperId">
              <div class="bn_l_n">
                <a-checkbox :value="it.courseWrapperId" name="type">
                </a-checkbox>
                <div class="bn_l_n_t">
                  <div>{{ it.courseName }}</div>
                  <div class="bn_l_n_t_b">
                    {{ it.courseYear }} | {{ it.hours }}学时
                  </div>
                </div>
              </div>
              <div class="bn_r_tbox">
                <div class="outlinedlog" @click="delcart(it)">
                  <DeleteOutlined></DeleteOutlined>
                </div>
              </div>
            </div>
          </a-checkbox-group>
        </div>
        <div class="pagebox">
          <!--          <div class="pages">-->
          <!--            <a-pagination v-model:current="current" :total="total" show-less-items/>-->
          <!--          </div>-->
        </div>
        <div class="addbox">
          <div>
            共选中<span class="hong"> {{ amt.courseTotal }}</span
            >门课程，共<span class="hong">{{ amt.courseHoursTotal }}</span
            >学时
          </div>
          <div class="addbox_r">
            <div class="butt_pri butt_lan" @click="signupbtn(1)">确认报名</div>
          </div>
        </div>
      </template>
    </Verification>
    <!-- 购物车结算 -->
    <Verification Title="购物车结算" width="700px" v-model:show="cartshowjs">
      <template #neirong>
        <a-spin :spinning="spinning" tip="加载中...">
          <div v-if="cartlist.length > 0">
            <div class="bn_l" v-for="it in cartlist" :key="it.courseWrapperId">
              <div class="bn_l_n">
                <!--              <a-checkbox :value="it.courseWrapperId" name="type">-->
                <!--              </a-checkbox>-->
                <div class="bn_l_n_t">
                  <div class="courseName">{{ it.courseName }}</div>
                  <div class="bn_l_n_t_b">
                    {{ it.courseYear }} | {{ it.hours }}学时
                  </div>
                </div>
              </div>
              <div class="bn_r_tbox">
                <div class="hong">￥ {{ it.price }}</div>
                <div class="outlinedlog" @click="delcart(it)">
                  <delete-outlined />
                </div>
              </div>
            </div>

            <!--          <a-checkbox-group v-model:value="modelRefx.cartjs" style="width: 100%;" >-->
            <!--          </a-checkbox-group>-->
          </div>
          <div v-else>
            <a-empty
              style="margin-top: 50px; margin-bottom: 50px"
              description="暂无数据"
            />
          </div>
          <div class="addbox">
            <div>
              共选中<span class="hong">{{ amt.courseTotal }}</span
              >门课程，共 <span class="hong">{{ amt.courseHoursTotal }}</span
              >学时
            </div>
            <div class="addbox_r">
              <div class="addbox_r_t">
                实付金额：<span class="hong">{{ amt.coursePriceTotal }}</span>
              </div>
              <div class="butt_pri" @click="signupbtn(2)">去结算</div>
            </div>
          </div>
        </a-spin>
      </template>
    </Verification>
    <!-- 确认付款 -->
    <Verification Title="确认付款" width="800px" v-model:show="Verifshow">
      <template #neirong>
        <!--        <div class="boxn">课程信息</div>-->
        <div>
          <!--          <a-checkbox-group v-model:value="modelRefx.type" style="width: 100%;">-->
          <!--          </a-checkbox-group>-->
          <div class="bn_l" v-for="it in cartlist" :key="it.courseWrapperId">
            <div class="bn_l_n">
              <!--              <a-checkbox :value="it.courseWrapperId" name="type">-->
              <!--              </a-checkbox>-->
              <div class="bn_l_n_t">
                <div class="courseName">{{ it.courseName }}</div>
                <div class="bn_l_n_t_b">
                  {{ it.courseYear }} | {{ it.hours }}学时
                </div>
              </div>
            </div>
            <!--            <div class="bn_r_t hong">-->
            <!--              ￥ {{ it.price }}-->
            <!--            </div>-->

            <div class="bn_r_tbox">
              <div>
                <div
                  class="yhq"
                  v-if="$store.state.urlAh == 'zyx' && it.couponAmount"
                >
                  <div style="color: #fcca00; margin-right: 30px">
                    (原价{{ it.price }}，已优惠{{ it.couponAmount }}元)
                  </div>
                  <div class="hong" style="text-decoration: line-through">
                    ￥ {{ it.price }}
                  </div>
                </div>
                <div class="hong">￥ {{ it.itemAmt }}</div>
              </div>
              <div v-if="deltype" class="outlinedlog" @click="delcart(it)">
                <delete-outlined />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="zf_t">支付方式</div>
            <div class="zf_fs">
              <div
                class="zf_fs_box"
                v-for="(it, index) in payModetype"
                :key="index"
                :class="payModeindex == it.payWay ? 'lan' : ''"
                @click="checkpayMode(it)"
              >
                <img
                  class="zf_img"
                  :src="require(`@/assets/img/${it.payWay}.png`)"
                  alt=""
                />{{ it.payWayName }}
              </div>
              <!--              <div class="zf_fs_box" :class="method == 1?'lan':''" @click="ClickMethod(1)">-->
              <!--                <img class="zf_img" src="../../assets/img/zhifubao.png" alt="">支付宝支付-->
              <!--              </div>-->
              <!--              <div class="zf_fs_box" :class="method == 2?'lan':''" @click="ClickMethod(2)">-->
              <!--                <img class="zf_img" src="../../assets/img/yinlian.png" alt="">银联支付-->
              <!--              </div>-->
            </div>
          </div>
        </div>
        <div></div>
        <div class="addbox">
          <div>
            共选中<span class="hong">{{ amt.courseTotal }}</span
            >门课程，共 <span class="hong">{{ amt.courseHoursTotal }}</span
            >学时
          </div>
          <div class="addbox_r">
            <div class="addbox_r_t">
              实付金额：<span class="hong">{{ amt.coursePriceTotal }}</span>
            </div>
            <div class="butt_pri" @click="confirmpay">确认付款</div>
          </div>
        </div>
      </template>
    </Verification>
    <payment ref="payment" @paystustrue="paytrue"></payment>
    <!--    登录弹窗-->
    <loginPopup ref="loginPopupref"/>
  </div>
</template>

<script>
import Verification from '@/components/Verification'
import payment from '../payment/index'
import {reactive, ref, toRefs} from 'vue'
import publicFn from '@/util/publicFn'
import { DeleteOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import loginPopup from '@/components/Centerlabel/loginPopup.vue'
import {useStore} from "vuex";
export default {
  name: 'pay_index',
  components: {
    Verification,
    DeleteOutlined,
    payment,
    loginPopup
  },
  setup(props, { emit }) {
    const loginPopupref=ref();
    let store = useStore()
    let _this = reactive({
      //报名列表选中
      cartchange() {
      },
      //确认付款弹出框控制
      Verifshow: false,
      opentype: false,
      spinning: false,
      deltype: false,
      payment: null,
      cartshow: false, //购物车付款弹框控制
      cartshowjs: false, //购物车结算控制
      modelRefx: {}, //课程列表选中状态
      listfk: [], //课程列表
      cartlist: [], //购物车列表
      payModetype: [], //支付方式
      amt: {
        courseTotal: 0,
        courseHoursTotal: 0,
        coursePriceTotal: 0,
      },
      payModeindex: 'weixin',
      // 打开登录弹窗
      fnOpenLogin(){
        let stores=store.state.urlAh;
        if(stores == 'yt'||stores == 'ta'||stores == 'lc'){
          setTimeout(()=>{
            loginPopupref.value.getParentdata()
          },500)
        }
      },
      //确认报名按钮
      signupbtn(type) {
        if (!localStorage.getItem('userinfo')) {
          message.success('请先登录系统！')
          _this.fnOpenLogin()
          return false
        }
        _this.getSettlementCourseWrapperDetailList()
        if (type == 1) {
          _this.cartshowjs = true
          _this.cartshow = false
        } else {
          if (_this.cartlist.length > 0) {
            _this.cartshowjs = false
            _this.Verifshow = true
            return false
          }
        }
      },
      //支付完成
      paytrue() {
        emit('Payment', 1)
      },
      //确认付款
      async confirmpay() {
        let courseWrapperIds = []
        _this.cartlist.map((v) => {
          courseWrapperIds.push(v.courseWrapperId)
        })
        //如果是微信支付
        if (_this.payModeindex == 'weixin') {
          let res = await publicFn.wxPayNative(courseWrapperIds)

          if (res.payInfo) {
            if (res.payInfo.paymentStatus == 1) {
              message.success('支付成功！')
              _this.Verifshow = false
              // window.location.reload()
              publicFn.routeJump({
                path: '/Personal/Classification',
              })
              emit('Payment', 1)
            } else {
              _this.Verifshow = false
              _this.payment.showModalqrcode(res.payInfo)
            }
          }
        } else {
          //支付宝支付
          let res = await publicFn.aliPayPc(courseWrapperIds)
          if (document.forms[0]) {
            document.forms[0].remove()
          }
          if (res.payInfo) {
            if (res.payInfo.paymentStatus == 1) {
              message.success('支付成功！')
              _this.Verifshow = false
              window.location.reload()
              emit('Payment', 1)
            } else {
              const div = document.createElement('div')
              div.innerHTML = res.payInfo.response.body
              document.body.appendChild(div)
              document.forms[0].submit()
            }
          }
        }
      },
      //获取购物车列表
      async getlist() {
        let res = await publicFn.getMyCartList()
        if (res.rows) {
          _this.cartlist = res.rows
        } else {
          _this.cartlist = []
        }
      },
      //打开购物车
      async opencart() {
        if (this.$store.state.cartTotal == 0) {
          message.success('暂无商品，请选择课程！')
          return false
        }
        //
        if (_this.opentype) {
          message.success('请勿重复点击！')
          return false
        }
        _this.opentype = true
        await _this.getPayModelist()
        await _this.getlist()
        _this.opentype = false

        _this.getSettlementCourseWrapperDetailList()
        _this.Verifshow = true
        _this.deltype = true
      },
      // 选择支付方式
      checkpayMode(e) {
        _this.payModeindex = e.payWay
      },
      //删除购物车
      async delcart(it) {
        _this.spinning = true
        let res = await publicFn.delcar([it.courseWrapperId])

        if (res.code) {
          await _this.getlist()
          await _this.getSettlementCourseWrapperDetailList()
          publicFn.getMyCartTotal()
          emit('del', 1)
          message.success(res.message)
        }
        _this.spinning = false
      },
      //获取支付方式
      async getPayModelist() {
        if (localStorage.getItem('userinfo')) {
          let res = await publicFn.getPayMode()
          if (res.rows) {
            _this.payModetype = res.rows
          } else {
            _this.payModetype = []
          }
        }
      },
      //打开付款样式
      paysignup(item) {
        publicFn
          .getCourseWrapperSettlementInfo({
            courseWrapperId: item.courseWrapperId,
          })
          .then((res) => {
            _this.cartlist = [res.rows[0]]
          })
        _this.getPayModelist()
        _this.Verifshow = true
        _this.deltype = false
        // _this.cartlist = [item]

        let obj = {
          '3da98f8fa7bf41fe8a5fecf7e9bb7a8e':
            '3da98f8fa7bf41fe8a5fecf7e9bb7a8e',
          d5be6a31902948abbf9bb09a2f0faa02: 'd5be6a31902948abbf9bb09a2f0faa02',
          dd42742fac2c4f6993290c21d0a3c3f4: 'dd42742fac2c4f6993290c21d0a3c3f4',
        }
        if (obj[item.courseWrapperId]) {
          let list = [
            {
              standard: 1,
              courseYear: 2022,
              courseType: 1,
              hours: 13,
              expertName: '朱启臻',
              orderItemId: '',
              courseWrapperId: '3da98f8fa7bf41fe8a5fecf7e9bb7a8e',
              cartNum: 0,
              topicType: 1,
              coursePic:
                'https://hezedatabackups-0218.oss-cn-shanghai.aliyuncs.com/2022/06/10/eb983b2b141d4b79bc219fb1a6590ead.jpg',
              courseName: '农业高质量发展和乡村振兴战略二',
              major: '农业高质量发展和乡村振兴战略',
              price: 15.6,
              topic: '农业高质量发展和乡村振兴战略',
              startTime: '2022-04-01',
              endTime: '2023-03-31',
            },
            {
              standard: 1,
              courseYear: 2022,
              courseType: 1,
              hours: 7,
              expertName: '朱启臻',
              orderItemId: '',
              courseWrapperId: 'd5be6a31902948abbf9bb09a2f0faa02',
              cartNum: 0,
              topicType: 1,
              coursePic:
                'https://hezedatabackups-0218.oss-cn-shanghai.aliyuncs.com/2022/06/10/dde550796a0b4f0fb67a5f1898b41aaf.jpg',
              courseName: '农业高质量发展和乡村振兴战略三',
              major: '农业高质量发展和乡村振兴战略',
              price: 8.4,
              topic: '农业高质量发展和乡村振兴战略',
              startTime: '2022-04-01',
              endTime: '2023-03-31',
            },
            {
              standard: 1,
              courseYear: 2022,
              courseType: 1,
              hours: 10,
              expertName: '孔祥智',
              orderItemId: '',
              courseWrapperId: 'dd42742fac2c4f6993290c21d0a3c3f4',
              cartNum: 0,
              topicType: 1,
              coursePic:
                'https://hezedatabackups-0218.oss-cn-shanghai.aliyuncs.com/2022/06/10/a10e2e58b0384e8799391b26b669b16e.jpg',
              courseName: '农业高质量发展和乡村振兴战略一',
              major: '农业高质量发展和乡村振兴战略',
              price: 12.0,
              topic: '农业高质量发展和乡村振兴战略',
              startTime: '2022-04-01',
              endTime: '2023-03-31',
            },
          ]
          _this.cartlist = list
          _this.getSettlementCourseWrapperDetailList([
            '3da98f8fa7bf41fe8a5fecf7e9bb7a8e',
            'd5be6a31902948abbf9bb09a2f0faa02',
            'dd42742fac2c4f6993290c21d0a3c3f4',
          ])
        } else {
          _this.getSettlementCourseWrapperDetailList([item.courseWrapperId])
        }
      },
      //查询金额
      async getSettlementCourseWrapperDetailList(list) {
        if (!list) {
          let courseWrapperIds = []
          _this.cartlist.map((v) => {
            courseWrapperIds.push(v.courseWrapperId)
          })
          list = courseWrapperIds
        }
        if (!list.length > 0) {
          _this.amt = {
            courseHoursTotal: 0,
            coursePriceTotal: 0,
            courseTotal: 0,
          }

          return false
        }
        let res = await publicFn.getSettlementCourseWrapperDetailList(list)
        if (res.courseTotal) {
          _this.amt = res
        } else {
          _this.amt = {
            courseHoursTotal: 0,
            coursePriceTotal: 0,
            courseTotal: 0,
          }
        }
      },
    })
    return {
      loginPopupref,
      ...toRefs(_this),
    }
  },
}
</script>
<style scoped>
.boxn {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #333333;
  padding-bottom: 15px;
}

.bn_l {
  background: #f2f5f5;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 15px;
}

.bn_img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.bn_l_n {
  display: flex;
  align-items: center;
}

.bn_l_n_t {
  margin-left: 10px;
  font-size: 18px;
}
.courseName {
  font-weight: 700;
}
.bn_l_n_t_b {
  font-size: 16px;
  color: #a7a7a7;
  margin-top: 5px;
}

.bn_r_t {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 800;
}

.bn_r_tbox {
  font-size: 18px;
  font-family: PingFang SC;
  display: flex;
  text-align: right;
}

.outlinedlog {
  margin-left: 90px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.lan {
  background: #eff5fd !important;
  border: 1px solid #1979fa !important;
}

.zf_fs_box {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 13px 26px;
  margin-right: 20px;
  cursor: pointer;
}

.zf_img {
  width: 30px;
  height: 30px;
  margin-right: 9px;
}

.zf_fs {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}

.zf_t {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #333333;
  margin-bottom: 15px;
}

.addbox {
  font-size: 18px;
  margin-top: 15px;
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e5e5e5;
}

.hong {
  color: red;
  font-size: 18px;
}

.cupotin {
  cursor: pointer;
}

.addbox_r {
  display: flex;
  align-items: center;
}

.addbox_r_t {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-right: 20px;
}

.butt_pri {
  padding: 7px 31px;
  background: #ee6b0b;
  border-radius: 4px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.butt_lan {
  background: #1168f3;
}

.pagebox {
  position: relative;
  height: 40px;
}

.pages {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.yhq {
  display: flex;
}
</style>
