<template>
  <div class="formcategory" :class="type == '1' ? 'formcategorypad' : ''">
    <template v-for="(it, index) in itemlist">
      <Category
        v-if="!it.typeCategory"
        :column="it"
        :footer="it.footer"
        @transmitValue="backemit"
        v-model:val="formval[it.key]"
        :key="index"
      ></Category>
    </template>
    <Category
      v-if="noShowCategoryPathData.filter(v => v == router.currentRoute.value.fullPath).length == 0"
      :column="{
        api: courseType != 1 ? 'major' : 'getTopicList',
        type: true,
        key: 'topic',
      }"
      footer="false"
      :courseType="courseType"
      ref="Category"
      @transmitValue="backemit"
      v-model:val="formval['topic']"
    ></Category>
  </div>
</template>

<script>
import Category from './index'
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  components: {
    Category,
  },
  name: 'formcategory_index',
  props: {
    list: {
      type: Array,
      default() {
        return []
      },
    },
    type: {
      type: String,
      default() {
        return '1'
      },
    },
    courseType: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const router = useRouter()
    const store = useStore()
    let _this = reactive({
      type: props.type,
      Category: null,
      CategoryT: false,
      noShowCategoryPathData: [
        '/Index',
        '/EngineeringTechnology',
        '/village',
        '/Personal/Examination',
        '/Personal/Classification',
        '/ChangingCareers',
      ], //不显示全部课程路由集合 
      //页面选中值
      formval: {},
      itemlist: props.list, //渲染数组
      //向父元素传值
      backemit(e) {
        if (_this.type == '1') {
          if (store.state.urlAh == 'ta' || store.state.urlAh == 'hz1'|| store.state.urlAh == 'yt') {
            if (_this.formval.topicType != '') {
              if (props.courseType == 1&&store.state.urlAh != 'ta'&&store.state.urlAh != 'yt') {
                  _this.formval.topic = ''
              }
              _this.CategoryT = true
              _this.formval[e.key] = e.val
              // if (!e.ts) {
              //   _this.formval.courseYear = e.val
              // } else {
              //   _this.formval.topicType = e.val
              // }
              setTimeout(() => {
                if (
                  _this.Category &&
                  e.api != 'major' &&
                  props.courseType == 1
                ) {
                  _this.Category.getapilist(_this.formval)
                }
              }, 500)
            } else {
              _this.CategoryT = false
            }
          } else {
            if (e.ts) {
              if (_this.formval.topicType != '') {
                _this.formval.topic = ''
                _this.CategoryT = true
                setTimeout(() => {
                  _this.Category.getapilist(_this.formval)
                }, 500)
              } else {
                _this.CategoryT = false
              }
            }
          }
        }
        emit('backemit', _this.formval)
      },
    })
    return {
      ...toRefs(_this),
      router,
    }
  },
}
</script>
<style scoped>
.formcategorypad {
  padding: 20px;
}
</style>
