<template>
  <div class="nav" :class="[tall == '首页' ? '' : 'shaw', type == 1 ? '' : hzShow ? 'hzNavah' : 'navah']">
    <div class="kuang widthcontent">
      <div class="kuang_ul" v-if="hzShow">
        <div class="div_li" v-for="(item, index) in $store.state.headList" :key="index" @click="topage(item.menuPath, item.menuName)">
          <div class="div_li_i" :class="[
              tall == item.menuName ? (tall == '首页' ? '' : '') : '',
              type == 1 ? 'hei' : '',
            ]"
            :style="{ width: 1200 / $store.state.headList.length + 'px' }"
          >
            {{ item.menuName }}
            <div
              v-if="
                tall == item.menuName ||
                item.menuPath.indexOf(path.slice(1)) != -1
              "
              :class="
                tall == '首页'
                  ? `div_li_click ${
                      $store.state.urlAh == 'ahkx' || $store.state.urlAh == 'ah'
                        ? 'bai'
                        : 'hzLan'
                    } `
                  : 'div_li_c_f hzLan '
              "
            ></div>
          </div>
        </div>
      </div>
      <div class="kuang_ul" v-else>
        <div
          class="div_li"
          v-for="(item, index) in $store.state.headList"
          :key="index"
          @click="topage(item.menuPath, item.menuName)"
        >
          <div
            class="div_li_i"
            :class="[
              tall == item.menuName ? (tall == '首页' ? '' : '') : '',
              type == 1 ? 'hei' : '',
            ]"
            :style="{ width: 1200 / $store.state.headList.length + 'px' }"
          >
            {{ item.menuName }}
            <div
              v-if="tall == item.menuName"
              :class="
                tall == '首页'
                  ? `div_li_click ${
                      $store.state.urlAh == 'ahkx' || $store.state.urlAh == 'ah'
                        ? 'bai'
                        : 'lan'
                    } `
                  : 'div_li_c_f lan '
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// scope
import { defineComponent, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'ZjNavbar',
  props: {
    type: {
      type: Number,
      default: () => {
        return 1
      },
    },
  },
  setup(props) {
    let Router = useRouter()
    let store = useStore()
    let hzShow = false
    let pages = store.state.urlAh
    if (pages == 'hz1' || pages == 'yt') {
      hzShow = true
    }
    let data = reactive({
      tall: Router.currentRoute.value.meta.title,
      path: Router.currentRoute.value.path,
      type: props.type,
      topage: (e, i) => {
        data.tall = i
        Router.push({ path: `/${e}` })
      },
    })

    watch(
      () => Router.currentRoute.value.meta.title,
      (newl) => {
        if (Router.currentRoute.value.path.slice(1, 11) == 'Helpcenter') {
          data.tall = '帮助中心'
        } else {
          data.tall = newl
        }
      }
    )
    watch(
      () => Router.currentRoute.value.path,
      (val) => {
        if (val) {
          data.path = val
        }
      }
    )
    return {
      ...toRefs(data),
      hzShow,
    }
  },
})
</script>

<style scoped>
.nav {
  /*background: #0952da;*/
  opacity: 1;
  color: #fff;
}
.navah {
  background: #0952da !important;
}
.hzNavah {
  background-color: transparent;
}
.kuang {
  margin: -2px auto;
}
.kuang_ul {
  display: flex;
  /* justify-content: space-between; */
  /*justify-content: center;*/
  /* background: red; */
}
.div_li {
  /* width: 20%; */
  /* background: #8df; */
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 700;
  /* padding-right: 5%;
  padding-left: 5%; */
  box-sizing: border-box;
  position: relative;
}
.div_li_i {
  cursor: pointer;
  padding: 0 14px 14px;
  /* background: #8df; */
  text-align: center;
}
.div_li:nth-child(n + 2)::before {
  content: '';
  width: 1px;
  height: 26px;
  background: #ffffff;
  position: absolute;
  top: 3px;
}

.div_li:nth-child(1)::before {
  content: '';
}
.div_li_click {
  height: 3px;
  width: 70%;
  position: relative;
  margin: 0 auto;
  top: 18px;
}
.div_li_click::before {
  content: '';
  background: #fff;
  color: #000;
  width: 64px;
  height: 4px;
  position: absolute;
  left: 50%;
  bottom: 6px;
  transform: translate(-50%);
}
.div_li_c_f {
  height: 3px;
  width: 70%;
  position: relative;
  margin: 0 auto;
  top: 18px;
}
.bai {
}
.bai::before {
  content: '';
  background: #fff;
  color: #000;
  width: 64px;
  height: 4px;
  position: absolute;
  left: 50%;
  bottom: 6px;
  transform: translate(-50%);
}
.lan {
  /* background: #1168f3 !important; */
}
.lan::before {
  content: '';
  background: #1168f3;
  color: #000;
  width: 64px;
  height: 4px;
  position: absolute;
  left: 50%;
  bottom: 6px;
  transform: translate(-50%);
}
.hzLan::before {
  content: '';
  background: #fff;
  color: #000;
  width: 64px;
  height: 4px;
  position: absolute;
  left: 50%;
  bottom: 6px;
  transform: translate(-50%);
}

.hei {
  color: #000;
}
</style>
