<template>
  <div class="boxs">
    <div class="ntime bom" :class="footer == 'false' ? '' : 'bd'">
      <div class="ntime_txt" v-if="Column.title">{{ Column.title }}:</div>
      <div
        class="ntime_txt_t"
        :class="keys === index ? 'lan' : ''"
        v-for="(item, index) in list"
        :key="index"
        @click="clickValue(item, index)"
      >
        {{ item.itemName }}
        <div class="xsDiv" v-if="tail == '首页'">|</div>
      </div>
      <!--      v-show="index < listlength"-->

      <!--      <div-->
      <!--        class="listmore ntime_txt_t"-->
      <!--        @click="openCourse"-->
      <!--        v-if="list.length > 17"-->
      <!--      >-->
      <!--        {{ listlength > 17 ? '收起' : '展开' }}-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import publicFn from '@/util/publicFn.js'
import { useRouter } from 'vue-router'

export default {
  name: 'Category_index',
  props: {
    column: {
      type: Object,
    },
    footer: {
      type: String,
    },
    courseType: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    let Router = useRouter()

    let data = reactive({
      keys: 0,
      listlength: 17,
      tail: Router.currentRoute.value.meta.title,

      Column: props.column, // 外部传递参数
      list: [{ itemName: '全部类型' }],
      //展开 收起
      openCourse() {
        if (data.listlength > 17) {
          data.listlength = 17
        } else {
          data.listlength = data.list.length
        }
      },
      //获取list
      getapilist(formval) {
        publicFn
          .post({
            serviceCode:
              props.column.api == 'major' ? 'getTopicList' : props.column.api,
            param: {
              topicType: formval.topicType,
              courseYear: formval.courseYear,
            },
          })
          .then((res) => {
            let arr = [{ itemName: '全部类型' }]
            res.rows.map((v) => {
              arr.push({ itemName: v.topic, itemValue: v.topic })
            })
            data.list = arr
            localStorage.setItem(props.column.api, JSON.stringify(res.rows))
          })
      },
      //获取字典值数据
      getCourseScreen() {
        publicFn
          .post({
            serviceCode: 'getCourseScreen',
            param: {
              dictCode: props.column.api,
            },
          })
          .then((res) => {
            // props.column.vllage  为true时是泰安乡村振兴栏
            let list = props.column.vllage
              ? res.rows.filter((item) => {
                  return item.itemName === '乡村振兴专题栏目'
                })
              : res.rows
            data.list = list
            localStorage.setItem(props.column.api, JSON.stringify(list))
            if (props.column.api === 'courseYear') {
              const year = new Date().getFullYear().toString()
              const yearIndex = res.rows?.findIndex(
                (item) => item.itemValue === year
              )
              if (yearIndex !== -1) {
                data.clickValue(res.rows[yearIndex], yearIndex)
              }
            }
          })
        // if(localStorage.getItem(props.column.api) && localStorage.getItem(props.column.api)!='[]'){
        //   data.list = JSON.parse(localStorage.getItem(props.column.api));
        // }else {
        //
        // }
      },
      clickValue(it, index) {
        data.keys = index
        emit('update:val', it.itemValue)
        emit('transmitValue', {
          val: it.itemValue,
          ...props.column,
        })
      },
    })
    if (props.courseType != 1) {
      data.getCourseScreen()
    } else {
      publicFn
        .post({
          serviceCode: 'getTopicList',
          param: {
            topicType: '',
            courseYear: '',
            courseType: props.courseType,
          },
        })
        .then((res) => {
          let arr = [{ itemName: props.column.api=='getTopicList'?'全部专题':'全部类型' }]
          res.rows.map((v) => {
            arr.push({ itemName: v.topic, itemValue: v.topic })
          })
          data.list = arr
        })
    }
    return {
      ...toRefs(data),
    }
  },
}
</script>

<style scoped>
.listmore {
  color: #1168f3 !important;
}
.lan {
  background: #1168f3;
  border-radius: 4px;
  color: #fff !important;
}

.boxs {
  background: #fff;
  border-radius: 10px;
}

.ntime {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  word-wrap: break-word;
  word-break: break-all;
}

.bom {
  margin-bottom: 18px;
}

.bd {
  border-bottom: 1px solid #ccc;
}

.ntime_txt {
  font-size: 17px;
  margin-top: 3px;
  cursor: pointer;
  width: 100px;
  height: 34px;
  line-height: 34px;
  border-radius: 5px;
  font-family: PingFang SC;
  font-weight: 500;
  margin-bottom: 10px;
}

.ntime_txt_t {
  cursor: pointer;
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-right: 29px;
  padding: 5px 10px;
  /*height: 30px;*/
  /*line-height: 30px;*/
  margin-bottom: 18px;
  position: relative;
}
.xsDiv {
  position: absolute;
  right: -24px;
  top: 4px;
  color: #cccccc;
}
.ntime :last-child .xsDiv {
  display: none;
}
</style>
