<template>
  <div class="Area">
    <a-spin :spinning="spinning" tip="加载中...">
      <div class="area_top">
        <div class="area_top_span" v-if="tail !== '首页'"></div>
        <div class="top_l">
          <div v-if="tail == '首页'">
            <img class="top_img" :src="logimg" alt="" />
          </div>
          <div class="b_txt" :class="tail == '首页' ? '' : 'b_txt_buto'">
            {{ title }}
          </div>
          <div v-if="tail == '首页'" class="screenDiv">
            <screen
              :list="sList"
              class="screen"
              @backemit="transmitValue"
            ></screen>
          </div>
        </div>

        <div>
          <div class="b_txt_r" @click="goMore" v-if="tail == '首页'">
            查看更多>
          </div>
          <div class="top_r" v-else>
            <div>搜索课程：</div>
            <div>
              <a-input-search
                v-model:value="labelInValuex"
                placeholder="请输入您要搜索的关键词"
                enter-button="搜索"
                style="font-size: 16px !important"
                @search="onSearch"
              />
            </div>
            <div class="top_r_s" @click="signup">
              <img
                class="ht_img"
                src="../../assets/img/zjhuantiao.png"
                alt=""
              />
              <img
                class="ht_img_ban"
                src="../../assets/img/zxhuanban.png"
                alt=""
              />
              <div class="top_r_s_t">
                <img
                  class="gwc_img"
                  src="../../assets/img/zjgouwuche.png"
                  alt=""
                />
                已选{{ carinfo.courseHoursTotal }}学时 | 总计{{
                  carinfo.courseTotal
                }}门课程
              </div>
              <div class="top_r_s_tb">报名</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="footer" v-if="list.length > 0">
          <div
            class="ul_Li"
            v-for="(item, index) in list"
            :key="index"
            v-show="index < limit"
          >
            <commodity :item="item"></commodity>
          </div>
          <div
            class="fei ul_Li"
            @click="signbm"
            v-if="title == '公需课选课' && $store.state.urlAh == 'ah'"
          >
            <div>
              <div style="text-align: center">公需课程</div>
              <div>点此报名缴费</div>
            </div>
          </div>
        </div>
        <div v-else style="text-align: center; width: 100%">
          <a-empty style="margin-top: 50px" description="暂无数据" />
        </div>
      </div>
      <div class="pagebox" v-if="tail !== '首页'">
        <div class="pages">
          <a-pagination
            v-model:current="shoucurrent"
            :defaultPageSize="12"
            :showSizeChanger="false"
            :total="total"
            @change="ChangeShoucurrent"
          />
        </div>
      </div>
      <pay ref="paycartArea"></pay>
    </a-spin>
    <!--    登录弹窗-->
    <loginPopup ref="loginPopupref"/>
  </div>
</template>

<script>
import {reactive, ref, toRefs, watch} from 'vue'
import { pay } from '@/components/payorder'
import commodity from '@/components/Area/commodity.vue'
import { useRouter } from 'vue-router'
import Verification from '@/components/Verification'
import { DeleteOutlined } from '@ant-design/icons-vue'
import publicFn from '@/util/publicFn.js'
import screen from '../screen/formcategory'
import loginPopup from '@/components/Centerlabel/loginPopup.vue'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'

export default {
  name: 'Area_index',
  props: {
    api: {
      type: String,
      default: () => {
        return 'getCoursePageList'
      },
    },
    path: {
      type: String,
      default: () => {
        return ''
      },
    },
    parm: {
      type: Object,
      default: () => {
        return {}
      },
    },
    title: {
      type: String,
      default: () => {
        return '公需课选课'
      },
    },
    logimg: {
      type: String,
      require: true,
    },
    limit: {
      type: Number,
      default: 4,
    },
  },
  components: {
    commodity,
    screen,
    pay,
    Verification,
    DeleteOutlined,
    loginPopup
  },
  setup(props) {
    const loginPopupref=ref();
    let Router = useRouter()
    let store = useStore()
    let _this = reactive({
      //筛选回调
      transmitValue(e) {
        _this.dataForm = e
        _this.getCoursePageList()
      },
      sList: [{ key: 'courseYear', api: 'courseYear', footer: 'false' }],
      current: 2, //分页器当前页数
      shoucurrent: 1, //首页分页器当前页数
      visible: false,
      limit: props.limit,
      paycartArea: null,
      spinning: false,
      title: props.title,
      dataForm: {
        courseYear: '',
      },
      list: [],
      seacherform: {},
      carinfo: {
        courseTotal: 0,
        courseHoursTotal: 0,
      }, //购物车信息
      total: 1, //分页总数量
      //获取课程列表
      getCoursePageList() {
        _this.spinning = true
        let obj = {
          ..._this.seacherform,
          ...props.parm,
          courseName: _this.labelInValuex,
        }
        if (_this.tail == '首页') {
          obj = {
            ..._this.seacherform,
            ...props.parm,
            courseName: _this.labelInValuex,
            ..._this.dataForm,
          }
        }
        publicFn
          .post({
            serviceCode: 'getCoursePageList',
            param: obj,
            page: {
              currentPage: _this.shoucurrent,
              showCount: props.limit,
            },
          })
          .then((res) => {
            _this.list = res.rows
            _this.spinning = false
            _this.total = res.page.totalCount
          })
          .catch(() => {
            _this.spinning = false
          })
      },
      //获取购物车数量
      async getMyCartList() {
        let res = await publicFn.getMyCartList()
        _this.carinfo = res
      },
      //搜索课程列表
      seacherList(val) {
        _this.seacherform = val

        _this.shoucurrent = 1

        _this.getCoursePageList()
      },
      //查看更多
      goMore() {
        Router.push({ path: props.path })
      },
      tail: Router.currentRoute.value.meta.title,
      labelInValuex: '',
      onSearch() {
        _this.shoucurrent = 1
        _this.getCoursePageList()
      },
      //立即报名
      signbm() {
        let item = { courseWrapperId: '3da98f8fa7bf41fe8a5fecf7e9bb7a8e' }
        if (localStorage.getItem('userinfo')) {
          _this.paycartArea.paysignup(item)
        } else {
          message.success('请先登录系统');
          _this.fnOpenLogin();
        }
      },
      signup: () => {
        if (localStorage.getItem('userinfo')) {
          _this.paycartArea.opencart()
        }
      },
      // 点击首页选择器
      ChangeShoucurrent: (e) => {
        _this.shoucurrent = e
        _this.getCoursePageList()
      },
      // 打开登录弹窗
      fnOpenLogin(){
        let stores=store.state.urlAh;
        if(stores == 'yt'||stores == 'ta'||stores == 'lc'){
          setTimeout(()=>{
            loginPopupref.value.getParentdata()
          },500)
        }
      },
    })
    _this.getCoursePageList()
    if (_this.tail !== '首页' && localStorage.getItem('userinfo')) {
      _this.getMyCartList()
    }
    watch(
      () => Router.currentRoute.value.meta.title,
      (newl) => {
        _this.tail = newl
      }
    )
    //监听购物车数量是否发生变化
    watch(
      () => store.state.cartTotal,
      () => {
        _this.getCoursePageList()
        if (_this.tail !== '首页' && localStorage.getItem('userinfo')) {
          _this.getMyCartList()
        }
      }
    )
    watch(
      () => props.title,
      () => {
        // console.log('新',newl);
      }
    )
    return {
      loginPopupref,
      ...toRefs(_this),
    }
  },
}
</script>
<style>
.screenDiv {
  overflow-y: hidden;
  position: relative;
  height: 80px;
  left: 20px;
}
.screen {
  position: relative;
  top: 3px;
}
.fei {
  font-size: 36px;
  display: flex;
  width: 334px;
  justify-content: center;
  align-items: center;
  background: url(../../assets/img/bian.png);
  background-size: 100% 100%;
  cursor: pointer;

  font-weight: bold;
  color: #ffffff;
  line-height: 60px;
}
.Area .ant-input-search-button span {
  font-size: 16px !important;
}
.Area .ant-input {
  border-radius: 4px !important;
}
</style>
<style scoped>
.Area {
  background: #fff;
  border-radius: 10px;
  /* padding: 20px 30px;  */
}

.area_top {
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  position: relative;
}
.area_top_span {
  position: absolute;
  left: 10px;
  bottom: 0;
  width: 100px;
  height: 5px;
  background: #2e7af4;
  /* border-bottom: 1px solid #2E7AF4; */
}

.top_l {
  display: flex;
  align-items: center;
}

.top_img {
  width: 30px;
  height: 30px;
}

.b_txt {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 25px;
  /* font-family: YouSheBiaoTiHei; */
  font-weight: 600;
  color: #222222;
  /*font-style: italic;*/
}

.b_txt_r {
  cursor: pointer;
  font-size: 18px;
  font-family: PingFang SC;
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 500;
  color: #1168f3;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  padding-top: 20px;
}

.ul_Li {
  /*height: 280px;*/
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  margin-right: 5px;
}

.top_r {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.ul_li_bx {
  position: absolute;
  left: -2px;
  top: -2px;
}

.ul_li_btt_bx {
  width: 67px;
  height: 33px;
}

.top_r_s {
  position: relative;
  cursor: pointer;
}

.ht_img {
  width: 336px;
  height: 46px;
  margin-left: 42px;
}

.ht_img_ban {
  width: 94px;
  height: 46px;
}

.top_r_s_t {
  position: absolute;
  top: 50%;
  left: 42%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
}

.gwc_img {
  width: 26px;
  height: 24px;
}

.top_r_s_tb {
  position: absolute;
  top: 50%;
  right: 4%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
}

.pages {
  text-align: right;
  /*position: absolute;*/
  /*right: 0;*/
  /*top: 50%;*/
  /*transform: translateY(-50%);*/
}
</style>
