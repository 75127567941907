<template>
  <div class="ZjLiconimg">
    <div class="jiaofei" v-if="reightcontrol">
      <!-- 移入 -->
      <div @mouseenter="reviewmouseenter">
        <img class="imss" src="../../assets/img/zhicehnghong.png" alt="" />
        <div
          class="jiaofei_t_box"
          v-if="reviewtype"
          @mouseleave="reviewmouseover"
        >
          <div
            class="jiaofei_t"
            @click="Contacttypeshow"
            :style="{
              'background-image':
                'url(' +
                'https://hezedatabackups-0218.oss-cn-shanghai.aliyuncs.com/2022/12/23/7910f9ee489543c99c15a1ca27fde07bhuangzhu.png' +
                ')',
            }"
          >
            点击免费为您解答
          </div>
          <div
            class="jiaofei_c"
            @click="Contacttypeshow"
            v-for="(it, index) in titleStep"
            :key="index"
          >
            {{ it.name }}
          </div>
          <div class="technologicalprocess">
            <div class="technolo_div">
              <div>
                <div class="technolo_img">
                  <img :src="require('@/assets/img/ps1.png')" alt="" />
                </div>
                <div class="technolo_title">1.确认申报 条件</div>
              </div>
              <div class="flex_center">
                <img
                  :src="require('@/assets/img/jty.png')"
                  class="jty"
                  alt=""
                />
              </div>
              <div>
                <div class="technolo_img">
                  <img :src="require('@/assets/img/ps2.png')" alt="" />
                </div>
                <div class="technolo_title">2.填写报名 申请表</div>
              </div>
              <div class="flex_center">
                <img
                  :src="require('@/assets/img/jty.png')"
                  class="jty"
                  alt=""
                />
              </div>
              <div>
                <div class="technolo_img">
                  <img :src="require('@/assets/img/ps3.png')" alt="" />
                </div>
                <div class="technolo_title">3.准备个人 信息资料</div>
              </div>
            </div>
            <div class="technolo_div" style="margin-bottom: 0">
              <div>
                <div class="technolo_img">
                  <img :src="require('@/assets/img/ps6.png')" alt="" />
                </div>
                <div class="technolo_title">6.等待网上 公示取证</div>
              </div>
              <div class="flex_center">
                <img
                  :src="require('@/assets/img/jtz.png')"
                  class="jty"
                  alt=""
                />
              </div>
              <div>
                <div class="technolo_img">
                  <img :src="require('@/assets/img/ps5.png')" alt="" />
                </div>
                <div class="technolo_title">5.人事单位 审核评定</div>
              </div>
              <div class="flex_center">
                <img
                  :src="require('@/assets/img/jtz.png')"
                  class="jty"
                  alt=""
                />
              </div>
              <div>
                <div class="technolo_img">
                  <img :src="require('@/assets/img/ps4.png')" alt="" />
                </div>
                <div class="technolo_title">4.提交业绩 材料证明</div>
              </div>
            </div>
            <div class="flex_center jtx">
              <img
                :src="require('@/assets/img/jtx.png')"
                class="jtx_img"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="logbox">
        <div
          v-if="
            $store.state.urlAh != 'zyx' &&
            $store.state.urlAh != 'hz1' &&
            $store.state.urlAhVersion != 'ahkx1'&&
            $store.state.urlAh != 'yt'
          "
          class="logbox_li_text"
          @mouseover="mouseLeave"
          @click="
            locationHref({
              path: '/SurroundingCity',
            })
          "
        >
          周边 城市 专技 平台
        </div>
        <div
          class="bd_box"
          v-if="
            $store.state.urlAh != 'zyx' &&
            $store.state.urlAh != 'hz1' &&
            $store.state.urlAhVersion != 'ahkx1'&&
            $store.state.urlAh != 'yt'
          "
          @mouseover="mouseLeave"
        ></div>
        <div class="logbox_li_text" @mouseover="mouseOver">
          {{ contact }}
          <div class="bnagzhu_box" v-if="contacttype" @mouseleave="mouseLeave">
            <div
              class="bnagzhu_box_topa c085de8"
              v-if="Displays && Displays.operatorPhone"
            >
              {{ Displays.operatorPhone.itemName }}:
            </div>
            <div
              class="bnagzhu_box_topb c085de8"
              style="font-style: italic"
              v-if="Displays && Displays.operatorPhone"
            >
              {{ Displays.operatorPhone.itemValue }}
            </div>
            <div v-if="Displays && Displays.workTime" class="gzr">
              <!-- {{
                $store.state.urlAh == 'zyx'
                  ? '(工作日:上午08:30-12:00 下午13:30-18:00)'
                  : '(工作日:上午08:30-12:00 下午13:00-18:00)'
              }} -->
              {{
                `(${Displays.workTime.itemName}:${Displays.workTime.itemValue})`
              }}
            </div>
            <!-- <div class="bnagzhu_box_topc" v-html="Displays.workTime.itemValue"></div> -->
            <!--            <div class="bnagzhu_box_topc" v-if="arr">{{ arr[0] }}</div>-->
            <!--            <div class="bnagzhu_box_topc" v-if="arr">{{ arr[1] }}</div>-->
            <!--            <div class="bnagzhu_box_topc" v-if="arr">{{ arr[2] }}</div>-->
            <!--            <div class="bnagzhu_box_top_xian"></div>-->
            <!--            <div class="bnagzhu_box_topa" style="margin-bottom: 8px;"-->
            <!--                 v-if="Displays&&Displays.operator">{{ Displays.operator.itemName }}:-->
            <!--            </div>-->

            <div class="bnagzhu_box_top_xian"></div>
            <!--            <div class="bnagzhu_box_topa" style="margin-bottom: 2px;" v-if="Displays&&Displays.consulting">-->
            <!--              {{ Displays.consulting.itemName }}:-->
            <!--            </div>-->
            <!--            <div class="bnagzhu_box_topf" v-if="Displays&&Displays.consulting">{{ Displays.consulting.itemValue }}</div>-->
            <!--            <div class="bnagzhu_box_top_xian"></div>-->
            <div
              class="bnagzhu_box_topa"
              style="margin-bottom: 8px"
              v-if="Displays && Displays.weCom"
            >
              {{ Displays.weCom.itemName }}:
            </div>
            <img
              class="bnagzhu_box_top_img"
              v-if="Displays && Displays.weCom"
              :src="Displays.weCom.itemPath"
              alt=""
            />
            <div v-if="$store.state.urlAh != 'ahkx'&&$store.state.urlAh != 'yt'">
              <div class="bnagzhu_box_top_xian"></div>
              <div
                class="bnagzhu_box_topa"
                v-if="Displays && Displays.operator"
              >
                {{ Displays.operator.itemValue }}
              </div>
              <div
                class="bnagzhu_box_topd"
                @click="Contacttypeshow"
                v-if="Displays && Displays.operator"
              >
                联系在线客服
              </div>
            </div>
          </div>
        </div>
        <div class="bd_box" @mouseover="mouseLeave"></div>
        <div class="logbox_li_gwc" @mouseover="mouseLeave" @click="opencartf">
          <img class="gwc_img" src="../../assets/img/gouwuchelan.png" alt="" />
          <div class="logbox_li_gwc_t">{{ $store.state.cartTotal }}</div>
        </div>
        <div class="bd_box"></div>
        <div
          style="text-align: center; cursor: pointer"
          @mouseover="mouseLeave"
        >
          <img
            class="youlog"
            src="../../assets/img/youshoushuo.png"
            alt=""
            @click="ClickCntrol"
          />
        </div>
      </div>
      <div
        class="logbox"
        style="text-align: center; cursor: pointer"
        @mouseover="mouseLeave"
      >
        <!-- 去顶部 -->
        <img
          class="log_img"
          src="../../assets/img/zhiding.png"
          alt=""
          @click="toTop"
        />
      </div>
    </div>
    <div class="shrink" v-else>
      <div class="">
        <img class="shouqiicon" src="../../assets/img/fwlog.png" alt="" />
      </div>
      <div
        class="logbox"
        style="text-align: center; cursor: pointer; position: relative"
        @click="clickcontrol"
      >
        展开
        <img class="youlog" src="../../assets/img/youdianji.png" alt="" />
      </div>
      <div class="zhiding" style="">
        <img
          class="log_img"
          src="../../assets/img/zhiding.png"
          alt=""
          @click="toTop"
        />
      </div>
    </div>
    <pay ref="payxx"></pay>

    <Verification Title="在线客服" v-model:show="Contacttype" width="600px">
      <template #neirong>
        <div class="web-chat-page">
          <iframe
            id="web-chat-iframe"
            height="100%"
            width="100%"
            frameborder="0"
            :src="iframeUrl"
          >
          </iframe>
        </div>
      </template>
      <!-- <template #neibutton>
         <a-button type="primary">确定</a-button>
      </template> -->
    </Verification>
  </div>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity'
import { pay } from '@/components/payorder'
import publicFn from '@/util/publicFn'
import { useStore } from 'vuex'
import { watch } from '@vue/runtime-core'

import Verification from '@/components/Verification'

export default {
  name: 'ZjLiconimg',
  components: {
    pay,
    Verification,
  },
  setup() {
    const store = useStore()
    let data = reactive({
      iframeUrl: '',
      //控制客服变量
      Contacttype: false,
      //点击弹出客服
      Contacttypeshow() {
        let userinfo = localStorage.getItem('userinfo')
        let url =
          'https://im.7x24cc.com/phone_webChat.html?accountId=N000000034003&chatId=1ddb23e3-f5ed-4433-bc44-1d28855e7bfe'
        if (userinfo) {
          userinfo = JSON.parse(userinfo)
          url = url + '&visitorld=' + userinfo.uid
        }
        data.iframeUrl = url
        data.Contacttype = true
      },
      contacttype: false,
      // 联系方式鼠标移出
      mouseLeave() {
        data.contacttype = false
      },
      //联系方式鼠标移入
      mouseOver() {
        data.contacttype = true
      },
      // 获取联系方式
      contact: store.state.layou.Displays.contact.itemValue || '联系我们',
      Displays: store.state.layou.Displays,
      //跳转路由
      locationHref: publicFn.routeJump,
      reightcontrol: true, //控制显示与隐藏
      distance: 0,
      // 获取滚动到顶部距离
      getScrollTop: () => {
        var scrollPos
        if (window.pageYOffset) {
          scrollPos = window.pageYOffset
        } else if (document.compatMode && document.compatMode != 'BackCompat') {
          scrollPos = document.documentElement.scrollTop
        } else if (document.body) {
          scrollPos = document.body.scrollTop
        }
        return scrollPos
      },
      // 去顶部
      toTop() {
        document.documentElement.scrollTop = 0
      },
      payxx: null, //购物车
      //打开购物车
      opencartf() {
        data.payxx.opencart()
      },
      // logc存储
      Setlocal(key, value, sTime) {
        let obj = {
          data: value,
          time: Date.now(),
          storageTime: sTime,
        }
        localStorage.setItem(key, JSON.stringify(obj))
      },
      // 取值
      Getlocal(key) {
        let obj = localStorage.getItem(key)
        obj = JSON.parse(obj)
        if (Date.now() - obj.time > obj.storageTime) {
          localStorage.removeItem(key)
          return null
        }
        return obj.data
      },
      // 打开
      clickcontrol() {
        // 存储
        data.Setlocal('reightcontrol', true, 7 * 24 * 60 * 60 * 1000)
        if (data.Getlocal('reightcontrol')) {
          data.reightcontrol = true
        } else {
          data.reightcontrol = false
        }
      },
      // 关闭
      ClickCntrol() {
        data.Setlocal('reightcontrol', false, 7 * 24 * 60 * 60 * 1000)
        if (data.Getlocal('reightcontrol')) {
          data.reightcontrol = true
        } else {
          data.reightcontrol = false
        }
      },
      arr: [],
      reviewtype: false,
      // 鼠标移入
      reviewmouseenter() {
        data.reviewtype = true
      },
      // 鼠标移出
      reviewmouseover() {
        data.reviewtype = false
      },
      // 职称评审步骤
      titleStep: [
        {
          name: '01.继续教育学时不够怎么申报?',
        },
        {
          name: '02.业绩成果怎么写才符合申报要求?',
        },
        {
          name: '03.职称申报下证时间是什么时候?',
        },
        {
          name: '04.职称评审有哪些条件变动?',
        },
        {
          name: '05.论文不符合要求怎么申报?',
        },
        {
          name: '06.职称申报有什么特殊优势?',
        },
        {
          name: '07.职称申报时间截至什么时候?',
        },
        {
          name: '08.职称申报怎么选择专业?',
        },
      ],
    })
    // 监听滚动距离顶部的距离，弹框是否展示
    window.onscroll = function () {
      data.distance = data.getScrollTop()
    }
    if (!localStorage.getItem('reightcontrol')) {
      data.reightcontrol = true
    } else if (data.Getlocal('reightcontrol')) {
      data.reightcontrol = true
    } else {
      data.reightcontrol = false
    }
    watch(
      () => store.state.layou.Displays.contact.itemValue,
      (newl) => {
        data.contact = newl
      }
    )
    watch(
      () => store.state.layou.Displays.workTime.itemValue,
      (newl) => {
        console.log(newl)
      }
    )
    data.arr = store.state.layou.Displays.workTime.itemValue.split('|')
    return {
      ...toRefs(data),
    }
  },
}
</script>

<style scoped>
.web-chat-page {
  width: 100%;
}

#web-chat-iframe {
  width: 100%;
  height: 600px;
}

.ZjLiconimg {
  z-index: 500;
  position: relative;
  position: fixed;
  right: 17px;
  top: 34%;
  width: 100px;
  height: 200px;
}

.jiaofei {
  position: fixed;
  right: 20px;
  z-index: 999;
  transform: translateY(-32%);
}

.shrink {
  position: fixed;
  /* bottom: 18%; */
  z-index: 500;
  right: 30px;
  z-index: 999;
  transform: translateY(179%);
}

.imss {
  /* width: 125px; */
  width: 160px;
}

.logbox {
  margin: 20px auto 0;
  width: 60px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 18px 7px;
  background: #fff;
}

.log_img {
  width: 38px;
  height: 38px;
}

.logbox_i {
  border-top: 1px solid #ccc;
}

@keyframes messer {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(50px, 500px);
  }
  40% {
    transform: translate(200px, 600px);
  }
  60% {
    transform: translate(600px, 600px);
  }
  80% {
    transform: translate(300px, 200px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.logbox_li_text {
  background: #0059e8;
  text-align: center;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 800;
  color: #ffffff;
  padding: 7px 0;
  cursor: pointer;
  padding: 5px;
}

/*.jiaofei:hover .bnagzhu_box {*/
/*  display: block;*/
/*}*/

.bd_box {
  border-top: 1px solid #ccc;
  margin-bottom: 12px;
  margin-top: 13px;
}

.youlog {
  width: 29px;
  height: 29px;
  margin-top: 5px;
}

.shouqiicon {
  width: 130px;
  /*height: 137px;*/
  position: absolute;
  right: -40px;
  top: -110px;
}

.logbox_li_gwc {
  position: relative;
}

.logbox_li_gwc_t {
  position: absolute;
  top: -0px;
  right: -5px;
  width: 25px;
  height: 25px;
  background: #d83535;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 800;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.gwc_img {
  margin-top: 10px;
  /* margin-bottom: 10px; */
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.zhiding {
  background: #fff;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc;
  position: absolute;
  margin-bottom: 12px;
  margin-top: 13px;
  padding: 20px 10px;
  border-radius: 10px;
}

.bnagzhu_box {
  /*display: none;*/
  position: absolute;
  top: 143px;
  left: 0;
  width: 193px;
  background: #fff;
  transform: translateX(-100%);
  padding: 10px 21px;
  border: 1px solid #cccccc;
  box-shadow: 0px 3px 9px 1px #e6e6e6;
  border-radius: 10px;
}
.c085de8 {
  color: #085de8 !important;
}
.bnagzhu_box_topa {
  font-size: 19px;
  font-family: PingFang SC;
  font-weight: 800;
  /*color: #085de8;*/
  color: #000;
}

.bnagzhu_box_topb {
  font-size: 17px;
  font-weight: 800;
  color: #333333;
}

.bnagzhu_box_topc {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
}

.bnagzhu_box_top_xian {
  border-top: 1px dashed #ccc;
  margin-top: 19px;
  margin-bottom: 14px;
}

.bnagzhu_box_topd {
  border: 1px solid #999999;
  border-radius: 6px;
  text-align: center;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  font-weight: 500;
  color: #333333;
}

.bnagzhu_box_topf {
  font-size: 17px;
  font-weight: 500;
  color: #333333;
}

.bnagzhu_box_top_img {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}
.jiaofei_t_box {
  position: absolute;
  top: -42px;
  left: -10px;
  background: #fff;
  transform: translateX(-100%);
  padding: 15px 14px;
  border: 1px solid #cccccc;
  box-shadow: 0px 3px 9px 1px #e6e6e6;
  border-radius: 10px;
}
.jiaofei_t {
  /* width: 492px; */
  background-repeat: no-repeat;
  background-size: 100% 101%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  color: #ffffff;
  cursor: pointer;
}
.jiaofei_c {
  margin-top: 8px;
  padding: 12px 30px;
  cursor: pointer;
  background: #f6f6f6;
  border-radius: 10px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #442d20;
}

.jtx_img {
  height: 50px;
}
.jtx {
  position: absolute;
  right: -10px;
  top: 0;
  height: 100%;
}
.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.technologicalprocess {
  padding: 15px;
  margin-top: 15px;
  border-top: 1px dashed #cccccc;
  position: relative;
  width: 400px;
}
.jty {
  width: 60px;
}
.technolo_div > div {
  width: 80px;
  text-align: center;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #442d20;
}
.technolo_div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.technolo_img {
  margin-bottom: 5px;
}
.technolo_img img {
  width: 50px;
  height: 50px;
}
.gzr {
  color: #888888;
  font-size: 12px;
}
</style>
