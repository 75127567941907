import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import componentPlugin from '@/components'
import Antd from 'ant-design-vue'
import { Modal } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css'
// 引入字体包
import './assets/font/font.css'
// 导入适配方案
import 'lib-flexible/flexible.js'
// 打印功能
import print from 'vue3-print-nb'

import { message } from 'ant-design-vue';
import publicFn from "@/util/publicFn";
import './util/permission.js'


message.config({
    top: `100px`,
    duration: 1,
    maxCount: 1,
    prefixCls: 'mymessage ant-message',
});

router.onError((error) => {
    const jsPattern = /Loading chunk (\S)+ failed/g
    const cssPattern = /Loading CSS chunk (\S)+ failed/g
    const isChunkLoadFailed = error.message.match(jsPattern || cssPattern)
    const targetPath = router.history.pending.fullPath
    if (isChunkLoadFailed) {
        localStorage.setItem('targetPath', targetPath)
        router.replace(targetPath)
    }
})
//2.路由发生变化修改页面title
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.documentElement.scrollTop = 0
        if(window.location.href.indexOf('yt.')!==-1){
            document.title = '烟台市人力资源发展促进会';
        }else{
            document.title = to.meta.title;
        }

    }
    next();
});

let initAPP = async ()=>{
    let href=window.location.href;
    // let href='https://ah.peixun.city/Index';

    store.commit('setUrlAh',false)
    if(href.indexOf('ah.tyjrpx')!==-1||href.indexOf('ah.sdjypx')!==-1){
        await publicFn.getAhUserInfo();
        // 安徽用户是否报名
        if(localStorage.getItem('userinfo')){
            let SignUp= await publicFn.getAHUserSignUp();
            if(SignUp&&SignUp.isEnroll==0){
                Modal.confirm({
                    title: '提示',
                    content: () =>
                        <div v-html={SignUp.title}/>
                    ,
                    okText: '立即报名',
                    cancelText: '暂不报名',
                    onOk() {
                        window.open('http://117.68.7.48:8081/center/#/train')
                    },
                },);
            }
        }
        store.commit('setUrlAh','ah')
    }
    if(href.indexOf('ah.peixun')!==-1||href.indexOf('zyx.sdjypx')!==-1){
        await publicFn.getAhUserInfo();
        store.commit('setUrlAh','zyx')
        // 安徽用户是否报名
        if(localStorage.getItem('userinfo')){
            let SignUp= await publicFn.getAHUserSignUp();
            if(SignUp&&SignUp.isEnroll==0){
                Modal.confirm({
                    title: '提示',
                    content: () =>
                        <div v-html={SignUp.title}/>
                    ,
                    okText: '立即报名',
                    cancelText: '暂不报名',
                    onOk() {
                        window.open('http://117.68.7.48:8081/center/#/train')
                    },
                },);
            }
        }
    }
    if(href.indexOf('ahkx.')!==-1){
        store.commit('setUrlAh','ahkx')
        if(href.indexOf('peixun.')!==-1){
          store.commit('setUrlAhVersion','ahkx1')
        }
    }
    if(href.indexOf('hz.')!==-1){
        await publicFn.getHzUserInfo();
        store.commit('setUrlAh','hz')
        store.commit('setHzType',true)
    }
    if(href.indexOf('lc.')!==-1){
        store.commit('setUrlAh','lc')
    }
    if(href.indexOf('ta.')!==-1){
        store.commit('setUrlAh','ta')
    }
    if(href.indexOf('yt.')!==-1){
        store.commit('setUrlAh','yt')
    }
    if(href.indexOf('jsxy.')!==-1){// 菏泽促进会
      await publicFn.getHzUserInfo();
        store.commit('setUrlAh','hz1')
        store.commit('setHzType',true)
    }
    // store.commit('setUrlAh','lc')
    // store.commit('setUrlAhVersion','ahkx1')// 新版
    // store.commit('setUrlAh','ta')
    // store.commit('setUrlAh','hz')
    // store.commit('setHzType',true)
    // store.commit('setUrlAh','ah')
    // store.commit('setUrlAh','yt')
    // store.commit('setUrlAh','zyx')
    // await publicFn.getAhUserInfo();
    await publicFn.getPlatformMenu();
    await publicFn.getPlatformInfo();
    // await publicFn.getAhUserInfo();
    // await publicFn.getHzUserInfo()
    createApp(App).use(Antd).use(store).use(router).use(print).use(componentPlugin).mount('#app')
}

initAPP()
