<template>
  <a-modal
    v-model:visible="registertype"
    :maskClosable="false"
    :footer="null"
    width="650px"
    :closable="false"
  >
    <template #title>
      <div class="titlebox">注册</div>
    </template>
    <a-form
      :model="register"
      @finish="registeronFinish"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-item
        label="手机号码"
        name="phone"
        :rules="[{ required: true, validator: phonerule }]"
        placeholder="请输入手机号"
      >
        <a-input v-model:value="register.phone" placeholder="请输入手机号" />
      </a-form-item>
      <a-form-item
        label="手机验证码"
        name="phoneCode"
        :rules="[{ required: true, message: '请输入验证码!' }]"
        placeholder="请输入验证码"
      >
        <div class="phoneCode">
          <div class="phoneCode_l">
            <a-input
              v-model:value="register.phoneCode"
              placeholder="请输入验证码"
            />
          </div>
          <div class="phoneCode_r" @click="sendPhoneCode">
            {{ btnText }}
          </div>
        </div>
      </a-form-item>
      <a-form-item
        label="所属区域"
        name="qy"
        :rules="[{ required: true, message: '请输入所属区域!' }]"
        placeholder="请输入所属区域"
      >
        <cascader
          v-model:modelValue="register.qy"
          placeholder="请输入所属区域"
        ></cascader>
      </a-form-item>
      <a-form-item
        v-if="$store.state.urlAh == 'ta'"
        name="companyName"
        label="单位名称"
        placeholder="请输入单位名称"
      >
        <a-input
          v-model:value="register.companyName"
          placeholder="请输入单位名称"
        />
      </a-form-item>
      <a-form-item
        label="密码"
        name="pwd"
        :rules="[{ required: true, message: '请输入密码!' }]"
      >
        <a-input-password
          v-model:value="register.pwd"
          placeholder="请输入密码"
          autocomplete="new-password"
        />
      </a-form-item>
      <a-form-item
        label="确认密码"
        name="pwdc"
        :rules="[{ required: true, validator: validatePass2 }]"
      >
        <a-input-password
          v-model:value="register.pwdc"
          placeholder="请输入密码"
        />
      </a-form-item>
      <a-form-item
        label="姓名"
        name="realName"
        :rules="[{ required: true, message: '请输入姓名!' }]"
      >
        <a-input v-model:value="register.realName" placeholder="请输入姓名" />
      </a-form-item>
      <a-form-item
        label="身份证号"
        name="identityNo"
        :rules="[{ required: true, validator: phoneNumberCheck }]"
        placeholder="请输入手机号"
      >
        <a-input
          v-model:value="register.identityNo"
          placeholder="请输入身份证号"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 16, offset: 6 }">
        <div class="fg_pwd_buttonbox">
          <a-button
            class="fg_pwd_btn_l"
            @click="registertype = false"
            size="large"
            >取消</a-button
          >
          <a-button
            class="fg_pwd_btn_l"
            style="margin-left: 15px"
            type="primary"
            html-type="submit"
            size="large"
            >注册</a-button
          >
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'
import cascader from '@/components/Signin/cascader.vue'
import http from '@/util/http'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'registers',
  components: { cascader },
  setup() {
    let store = useStore()
    let data = reactive({
      registertype: false,
      btnText: '发送验证码',
      isClick: false,
      currentTime: 60,
      register: {},
      //注册提交
      registeronFinish(val) {
        http
          .post({
            serviceCode: 'regUserInfo',
            param: {
              ...val,
              identityType: 1,
              province: val.qy.provinceCode,
              city: val.qy.cityCode,
              county: val.qy.countyCode,
            },
          })
          .then((res) => {
            localStorage.setItem('userinfo', JSON.stringify(res))
            store.commit('layou/setUserinfo', res)
            message.success(res.message)
            store.commit('layou/setavatar', res.userInfo.avatar)
            data.registertype = false
          })
      },
      //发送验证码
      sendPhoneCode() {
        if (data.isClick) {
          return false
        }
        //验证手机号
        if (!data.yzphone()) {
          return false
        }
        data.isClick = true
        let currentTime = data.currentTime
        http
          .post({
            serviceCode: 'sendPhoneCode',
            param: {
              phone: data.register.phone,
              type: 2,
            },
          })
          .then(() => {
            let timeInterval = setInterval(function () {
              currentTime-- //倒计时
              data.btnText = currentTime + ''
              if (currentTime <= 0) {
                clearInterval(timeInterval) //重置倒计时
                data.btnText = '发送验证码'
                data.currentTime = 60
                data.isClick = false
              }
            }, 1000)
            message.success('发送验证码成功')
          })
          .catch(() => {
            data.isClick = false
          })
      },
      //发送验证码验证手机号
      yzphone() {
        if (data.register.phone === '') {
          message.error('请输入手机号')
          return false
        } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(data.register.phone)) {
          message.error('手机号格式不正确')
          return false
        } else {
          return true
        }
      },
      // 父级页面调用
      getParentdata() {
        for (const key in data.register) {
          data.register[key] = ''
        }
        data.registertype = true
      },
    })
    return {
      ...toRefs(data),
    }
  },
}
</script>

<style scoped>
.phoneCode {
  display: flex;
  justify-content: space-between;
}

.phoneCode_l {
  width: calc(100% - 100px);
}

.phoneCode_r {
  width: 100px;
  background: #40a9ff;
  color: #fff;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  height: 32px;
}
.fg_pwd_buttonbox {
  text-align: right;
}
.fg_pwd_btn_l {
  width: 100px;
}
</style>
